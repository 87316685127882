@import "icon-variables.scss";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?nfwto6') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?nfwto6') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?nfwto6##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}

.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}

.icon-switch {
  &:before {
    content: $icon-switch; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}

.icon-back {
  &:before {
    content: $icon-back; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-logout {
  &:before {
    content: $icon-logout; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}

