$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../../fonts" !default;

$icon-calendar: "\e90b";
$icon-upload: "\e909";
$icon-settings: "\e90a";
$icon-switch: "\e908";
$icon-search: "\e907";
$icon-back: "\e900";
$icon-close: "\e901";
$icon-delete: "\e902";
$icon-edit: "\e903";
$icon-eye: "\e904";
$icon-logout: "\e905";
$icon-play: "\e906";

